import React, { useState, useEffect, Fragment, useRef } from "react";

import { useReactiveVar } from "@apollo/client";
import {
  ArrowLeft,
  ChevronRight,
  ChevronDown,
  ChevronUp,
  Link2,
  Check,
} from "lucide-react";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { quickViewStackVar } from "@/lib/local-state";
import { QuickViewRegistry } from "@/lib/quickview-registry";
import { copyText } from "@/lib/utils";

const QuickView = ({
  id,
  children,
  open,
  onOpenChange,
  onCloseComplete,
  initialTitle,
  initialSubtitle,
  xPadding = true,
  size = "sm",
  triggerComponent,
  requiresDiscard = false,
  onNext = null,
  onPrev = null,
}) => {
  const [title, setTitle] = useState(initialTitle);
  const [subtitle, setSubtitle] = useState(initialSubtitle);
  const [icon, setIcon] = useState(null);
  const [withControls, setWithControls] = useState(false);
  const [componentSize, setSize] = useState(size);
  const [headerControl, setHeaderControl] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);
  const [showNextTooltip, setShowNextTooltip] = useState(false);
  const [showPrevTooltip, setShowPrevTooltip] = useState(false);
  const unsaveChangesRef = useRef(false);
  const qvStack = useReactiveVar(quickViewStackVar);

  const handleClose = (value) => {
    if (requiresDiscard && unsaveChangesRef.current) {
      setShowConfirmDialog(true);
    } else {
      onOpenChange(value);
    }
  };

  const copyLinkToClipboard = () => {
    copyText(window.location.href);
    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 2000);
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        setTitle,
        setSubtitle,
        setIcon,
        onOpenChange: handleClose,
        setWithControls,
        setSize,
        setHeaderControl,
        unsaveChangesRef,
        onNext: (quickviewId) => onNext(quickviewId),
        onPrev: (quickviewId) => onPrev(quickviewId),
      });
    }
    return child;
  });

  useEffect(() => {
    if (open) {
      setSize(size);
      unsaveChangesRef.current = false;
      setShowCopyTooltip(false);
      setShowNextTooltip(false);
      setShowPrevTooltip(false);
    }
  }, [open]);

  const contentHeight = withControls
    ? "h-[calc(100%_-_8rem)]"
    : "h-[calc(100%_-_4rem)]";

  const showNavigationButtons = onNext !== null || onPrev !== null;

  return (
    <>
      <Sheet open={open} onOpenChange={handleClose}>
        {triggerComponent && (
          <SheetTrigger asChild>{triggerComponent}</SheetTrigger>
        )}
        <SheetContent
          onCloseAutoFocus={onCloseComplete}
          className={`quickview ${componentSize} transition-all duration-150 dark:bg-zinc-900 border-0`}
        >
          <div className="absolute right-7 top-4 -mt-[1px] flex items-center z-50">
            <div className="flex gap-2 mr-5">
              <TooltipProvider delayDuration={300}>
                <Tooltip open={showCopyTooltip}>
                  <TooltipTrigger asChild>
                    <button
                      onClick={copyLinkToClipboard}
                      onMouseEnter={() => setShowCopyTooltip(true)}
                      onMouseLeave={() => setShowCopyTooltip(false)}
                      className="flex items-center justify-center h-5 w-5 rounded-sm opacity-70 hover:opacity-100 hover:bg-slate-100 dark:hover:bg-zinc-800 cursor-pointer transition-opacity"
                      aria-label="Copy link"
                    >
                      {linkCopied ? (
                        <Check className="h-5 w-5 text-green-500 dark:text-green-400" />
                      ) : (
                        <Link2 className="h-5 w-5 dark:text-zinc-500" />
                      )}
                    </button>
                  </TooltipTrigger>
                  <TooltipContent side="bottom" className="text-xs">
                    <p>{linkCopied ? "Copied!" : "Copy link"}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>

              {showNavigationButtons && (
                <>
                  <TooltipProvider delayDuration={300}>
                    <Tooltip open={showNextTooltip}>
                      <TooltipTrigger asChild>
                        <button
                          onClick={onNext ? () => onNext(id) : undefined}
                          onMouseEnter={() => setShowNextTooltip(true)}
                          onMouseLeave={() => setShowNextTooltip(false)}
                          className={`ml-3 flex items-center justify-center h-5 w-5 rounded-sm ${
                            onNext
                              ? "opacity-70 hover:opacity-100 hover:bg-slate-100 dark:hover:bg-zinc-800 cursor-pointer"
                              : "opacity-30 cursor-not-allowed"
                          } transition-opacity`}
                          aria-label="Next item"
                          disabled={!onNext}
                        >
                          <ChevronDown className="h-6 w-6 dark:text-zinc-500" />
                        </button>
                      </TooltipTrigger>
                      <TooltipContent side="bottom" className="text-xs">
                        <p>{onNext ? "Next" : "No next item"}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>

                  <TooltipProvider delayDuration={300}>
                    <Tooltip open={showPrevTooltip}>
                      <TooltipTrigger asChild>
                        <button
                          onClick={onPrev ? () => onPrev(id) : undefined}
                          onMouseEnter={() => setShowPrevTooltip(true)}
                          onMouseLeave={() => setShowPrevTooltip(false)}
                          className={`flex items-center justify-center h-5 w-5 rounded-sm ${
                            onPrev
                              ? "opacity-70 hover:opacity-100 hover:bg-slate-100 dark:hover:bg-zinc-800 cursor-pointer"
                              : "opacity-30 cursor-not-allowed"
                          } transition-opacity`}
                          aria-label="Previous item"
                          disabled={!onPrev}
                        >
                          <ChevronUp className="h-6 w-6 dark:text-zinc-500" />
                        </button>
                      </TooltipTrigger>
                      <TooltipContent side="bottom" className="text-xs">
                        <p>{onPrev ? "Previous" : "No previous item"}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </>
              )}
            </div>
          </div>

          <SheetHeader className="header">
            {qvStack.length > 1 && (
              <div className="absolute top-0 left-0 py-1.5 px-3 flex items-center gap-2 rounded-tl-xl rounded-br-md bg-zinc-100 dark:bg-zinc-700">
                <ArrowLeft
                  size={16}
                  className="cursor-pointer text-zinc-500 hover:text-zinc-800 dark:text-zinc-400 dark:hover:text-zinc-100"
                  onClick={() => handleClose(false)}
                />

                <div className="flex items-center gap-1 text-xs text-slate-600 dark:text-zinc-400">
                  {qvStack.map((view, index) => {
                    const registration = QuickViewRegistry[view.type];
                    const { initialTitle } = registration.defaultProps;
                    return (
                      <Fragment key={index}>
                        <span key={index}>{initialTitle}</span>
                        {index < qvStack.length - 1 && (
                          <ChevronRight size={14} />
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            )}
            <SheetTitle className="!mb-4 !pb-4 mx-[50px] border-b border-gray-200 dark:border-zinc-700">
              <div className="flex flex-col items-start lg:flex-row gap-3 lg:items-center">
                <div className="flex flex-row-reverse w-full lg:w-auto lg:flex-1 lg:flex-row items-start lg:items-center gap-2 text-left">
                  <div>{icon}</div>
                  <div className="flex-1 flex flex-col items-start">
                    <span className="block leading-none mb-1 dark:text-zinc-300">
                      {title}
                    </span>
                    <span className="text-xs font-normal text-slate-600 dark:text-zinc-500">
                      {subtitle}
                    </span>
                  </div>
                </div>
                {headerControl && <div>{headerControl}</div>}
              </div>
            </SheetTitle>
          </SheetHeader>

          <div
            className={`overflow-y-auto ${
              xPadding ? "px-[50px]" : ""
            } ${contentHeight}`}
          >
            {childrenWithProps}
          </div>
        </SheetContent>
      </Sheet>

      <AlertDialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Discard changes?</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to leave? Your changes will be lost.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setShowConfirmDialog(false)}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                setShowConfirmDialog(false);
                onOpenChange(false);
              }}
            >
              Yes, discard
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default QuickView;
