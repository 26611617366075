import { useReactiveVar } from "@apollo/client";

import { userDataVar } from "@/lib/local-state";

export const usePermission = () => {
  const userData = useReactiveVar(userDataVar);

  const hasPermission = (permission) =>
    userData?.permissions?.includes(permission) ||
    userData?.roles?.some((role) => role.name === "SYSTEM_ADMIN");

  const hasRole = (role) =>
    userData?.roles?.some((userRole) => userRole.name === role);

  return {
    userPermissions: userData?.permissions || [],
    userRoles: Array.from(
      new Set(userData?.roles?.flatMap((role) => role.name)),
    ),
    systemAdmin: userData?.roles?.some((role) => role.name === "SYSTEM_ADMIN"),
    hasPermission,
    hasRole,
  };
};
